import { iStore } from "../../store.model";

export const selectMessageByConversationId = (conversationId: string) => (
  state: iStore
) =>
  state.messages.byConversations[conversationId] || {
    data: [],
    fetchedPages: 0,
    newMessages: 0,
    unread: 0,
    isFullyFetched: false,
    isFetching: false,
    hasError: false,
  };

export const selectUnreadCount = (conversationId: string) => (state: iStore) =>
  state.messages.byConversations[conversationId]?.unread || 0;
