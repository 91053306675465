import classNames from "classnames";
import React from "react";
// import "react-perfect-scrollbar/dist/css/styles.css";

export const PageScrollContainer = ({ children, className, ...props }) => {
  //     let prefectScrollbarVisible = useNavigationAnimationPatch();
  //     prefectScrollbarVisible = false;
  //   if (prefectScrollbarVisible) {
  //     return <_PerfectScrollbar {...props}>{children}</_PerfectScrollbar>;
  //   }
  return (
    <div
      className={classNames("page-overflow-container", className)}
      {...props}
    >
      {children}
    </div>
  );
};
