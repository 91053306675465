import { Store } from "@reduxjs/toolkit";
import { SDK } from "@sdk";
import { iSessionInitialData, iWidget } from "@sdk/services/models";
import { iStore } from "store/store.model";
import {
  addActivatedCheckList,
  setActivatedCheckLists,
  setActivatedMessageCampaigns,
  setActivatedTours,
  setNewsfeedTypes,
  setSession,
  setTicketTypes,
  setWidget,
} from "./session.actions";
import { selectWidget } from "./session.selectors";
import { setConversationsQueryResults } from "../conversations/conversations.slice";
import {
  defaultQueryValue,
  setPaginatedQueryListState,
  setQueryListState,
} from "store/utils/list-query";
import { setTicketsQueryResults } from "../tickets/tickets.slice";
import { ParentWindowService } from "@parent-window/parent-window";
import { isInIframe } from "utils/is-in-iframe";
import { justWait } from "utils/just-wait";

export const loadWidget = (widgetId: string) => (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  const loadAsync = async () => {
    if (!forceReload && isInIframe()) {
      ParentWindowService.sendMessage({
        type: "GET_WIDGET_CONFIG",
        data: {},
      });
      await justWait(200);
    } else {
      await SDK.getWidgetConfigurations(widgetId).then((data) => {
        store.dispatch(setWidget(data as iWidget));
      });
    }
  };
  return loadAsync();
};

const buildPaginatedResponse = <T>(docs: T[]) => {
  return {
    docs: docs,
    totalDocs: docs.length,
    limit: 6,
    page: 1,
    totalPages: 1,
    offset: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: 0,
    nextPage: 0,
    pagingCounter: 0,
  };
};

export const sesSessionInitialData = (
  store: Store<iStore>,
  data: iSessionInitialData
) => {
  store.dispatch(setSession(data.session));
  const conversations = data?.conversations?.docs || [];
  const tickets = data?.tickets?.docs || [];
  const tourLogs = data?.tourLogs?.docs || [];
  const checkListInstance = data?.checkListInstance?.docs || [];
  store.dispatch(
    setConversationsQueryResults({
      query: "min",
      results: buildPaginatedResponse(conversations),
    })
  );
  store.dispatch(
    setTicketsQueryResults({
      query: "min",
      results: buildPaginatedResponse(tickets),
    })
  );
  store.dispatch(setActivatedCheckLists(checkListInstance));
  store.dispatch(setActivatedTours(tourLogs));
};

export const loadInitialData = (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  return SDK.getFullSessionData().then((data) => {
    sesSessionInitialData(store, data);
  });
};

export const loadSession = (store: Store<iStore>, forceReload?: boolean) => {
  return SDK.getSessionData().then((data) => {
    store.dispatch(setSession(data));
  });
};

export const loadTicketTypes = (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  return SDK.getAllTicketTypes().then((data) => {
    store.dispatch(setTicketTypes(data));
  });
};

export const loadActivatedMessageCampaigns = (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  return SDK.getActiveMessageCampaigns().then((data) => {
    store.dispatch(setActivatedMessageCampaigns(data));
  });
};

export const loadActivatedTours = (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  return SDK.getActiveTours().then((data) => {
    store.dispatch(setActivatedTours(data));
  });
};

export const loadNewsfeedTypes = (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  const widget = selectWidget(store.getState());
  // console.log("widget", widget);
  return SDK.getAllNewsfeedConfig(widget?.organizationId!).then((data) => {
    store.dispatch(setNewsfeedTypes(data));
  });
};

export const loadActivatedCheckLists = (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  return SDK.checkLists.getAllCheckLists().then((data) => {
    store.dispatch(setActivatedCheckLists(data));
  });
};

export const loadCheckListById = (checkListId: string) => (
  store: Store<iStore>
) => {
  return SDK.checkLists.getChecklistById(checkListId).then((data) => {
    store.dispatch(addActivatedCheckList(data));
  });
};
