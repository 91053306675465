import { SocketConnector } from "@sdk/@libs/socket-connector";
import debounce from "lodash/debounce";
import { Store } from "redux";
import watch from "redux-watch";
import { persistorSelector } from "./modules/app-state/app-state.selectors";
import {
  selectConversationsAllList,
  selectConversationsQueryList,
} from "./modules/conversations/conversations.selectors";
import { loadSession } from "./modules/session/session.helpers";
import { selectContact } from "./modules/session/session.selectors";
import { iStore } from "./store.model";

type SelectorFunction<T> = (state: iStore) => T;
type WatchCallback<T> = (newVal?: T, oldVal?: T, objectPath?: string) => void;

export const RegisterWatchers = (store: Store) => {
  function TypedWatch<T>(
    selector: SelectorFunction<T>
  ): (callback: WatchCallback<T>) => () => any {
    return watch(() => selector(store.getState()));
  }

  /**
   *
   * Initiate
   *
   */

  const onStateRehydrate = debounce((newVal, oldVal, objectPath) => {
    // Write functions that needs to be firing when store initiated
  }, 200);

  const onAccessTokenChange = debounce((token, oldVal, objectPath) => {
    // Write functions that needs to be firing when store initiated
    // SDK.configure({
    //   token: token
    // });
    // // On Logout, no need to load the account
    // if (token) {
    //   loadOrganization(store).catch(e => {
    //     console.log("Error while loading organization");
    //     // Failed. Ideally Logout
    //     store.dispatch(logout());
    //   });
    //   loadAllUsers()(store, true).catch(e => {
    //     console.log("Error while loading all users");
    //     // Failed. Ideally Logout
    //     store.dispatch(logout());
    //   });
    //   if (!selectCurrentUser(store.getState())) {
    //     reloadCurrentUser(store).catch(e => {
    //       console.log("Error while loading user");
    //       // Failed. Ideally Logout
    //       store.dispatch(logout());
    //     });
    //   }
    // }
    // // Connect and Disconnect Socket Server // Probably have to move this to main component
    // if (token) {
    //   SocketConnector.connect(token);
    //   HandleSocketEvents(SocketConnector.socket, store);
    // } else {
    //   SocketConnector.disconnect();
    // }
  }, 200);

  const onContactUpdated = debounce((contact, oldVal, objectPath) => {
    // const contact = selectContactId(store.getState());
    // if (!contact && contactId) {
    //   loadSession(store);
    // }
    if (contact && contact.id) {
      // * Quick Patch. Ideally Contact Id should be listed from socket server automatically
      SocketConnector.socket.emit("WATCH_ENTITIES", [contact.id]);
    }
  }, 200);

  const onConversationListChanged = (conversationList?: string[]) => {
    if (conversationList) {
      if (conversationList.length > 0) {
        SocketConnector.socket.emit(
          "WATCH_ENTITIES",
          (conversationList || [])
            .filter((e) => !e.includes("TEMP"))
            .map((conversationId) => [
              conversationId,
              `TYPING_${conversationId}`,
            ])
            .flat()
        );
      }
    }
  };

  /**
   *
   * Watchers
   *
   */

  const persistStateWatcher = TypedWatch(persistorSelector)(onStateRehydrate);
  // const accessTokenWatcher = TypedWatch(selectAccessToken)(onAccessTokenChange);
  const contactWatcher = TypedWatch(selectContact)(onContactUpdated);
  const conversationListWatcher = TypedWatch(selectConversationsAllList)(
    onConversationListChanged
  );
  const conversationListWatcher2 = TypedWatch(
    selectConversationsQueryList("min")
  )(onConversationListChanged);

  store.subscribe(() => {
    // Add Watchers Here
    persistStateWatcher();
    // accessTokenWatcher();
    conversationListWatcher();
    conversationListWatcher2();
    contactWatcher();
  });
};
