import { useEffect, useMemo } from "react";
import { ParentWindowService } from "./parent-window";
import { useSelector } from "react-redux";
import {
  selectSession,
  selectWidget,
} from "store/modules/session/session.selectors";
import { SocketConnector } from "@sdk/@libs/socket-connector";
import { useTranslation } from "react-i18next";

export const useEstablishParentWindowSync = () => {
  const { t, i18n } = useTranslation("common");
  const session = useSelector(selectSession);

  /******************
   * SESSION_VERIFICATION_UPDATED *
   ******************/

  useEffect(() => {
    const isVerified = session?.metaData?.isVerified;
    // const sessionToken = sessionToken
    ParentWindowService.sendMessage({
      type: "SESSION_VERIFICATION_UPDATED",
      data: {
        isVerified,
        // Todo: Check if the below logic is correct
        sessionToken: SocketConnector.lastToken,
        contactId: session?.contactId,
      },
    });
  }, [session?.contactId, session?.metaData?.isVerified]);

  /******************
   * SYNCING LOCALE *
   ******************/
  const localConfigForParents = useMemo(
    () => ({
      REPLY: t("REPLY"),
      DISMISS: t("DISMISS"),
      USER_FROM_COMPANY: t("USER_FROM_COMPANY"),
      NEW_MESSAGE_TAB_MESSAGE: t("NEW_MESSAGE_TAB_MESSAGE"),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, i18n.language]
  );

  useEffect(() => {
    ParentWindowService.sendMessage({
      type: "UPDATE_LOCALE",
      data: localConfigForParents,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ParentWindowService.sendMessage({
      type: "UPDATE_LOCALE",
      data: localConfigForParents,
    });
  }, [localConfigForParents]);

  /*************************
   * SYNC THEME AND CONFIG *
   *************************/

  const widget = useSelector(selectWidget);

  useEffect(() => {
    if (widget) {
      ParentWindowService.sendMessage({
        type: "UPDATE_WIDGET_CONFIGURATIONS",
        data: widget,
      });
    }
  }, [widget]);
};
