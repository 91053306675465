import { useCallback, useEffect, useMemo, useState } from "react";

import { message } from "antd";
import { useDispatch } from "react-redux";
import { useStateIfMounted } from "use-state-if-mounted";
import { useStateIfMountedX } from "utils/use-state-if-mounted";
import { SDK } from ".";
import { GetSafely } from "../utils/get-safely";
import { PaginatedOptions, PaginatedResults } from "./utils/paginated-results";
import { useSimpleState } from "./utils/use-simple-state";

export const useSDK = <T extends unknown>(
  callFunction: (sdk: typeof SDK) => Promise<T>,
  dependencies: any[] = [],
  disable?: boolean,
  defaultValue = {}
) => {
  const [hasError, setHasError] = useState(false);
  const [data, setData] = useState(defaultValue as T);
  const [isLoading, setLoading] = useState(true);
  const [reloadCounter, setReloadCounter] = useState(0);
  const reload = () => {
    setReloadCounter(reloadCounter + 1);
  };
  useEffect(() => {
    setLoading(true);
    const callAPI = async () => {
      let d = await callFunction(SDK);
      setData(d);
      setLoading(false);
    };
    if (!disable) {
      try {
        callAPI();
        setHasError(false);
      } catch (e) {
        setHasError(true);
      }
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, reloadCounter]);
  return { data, isLoading, error: hasError, reload, setData };
};

export const showErrorMessage = (e: any, errorMessage?: string) => {
  const failureMessage = GetSafely(() => e.response.data.message, errorMessage);
  errorMessage && message.error(failureMessage);
};

export const useSDKAction = <T extends unknown>(
  config: {
    action: (sdk: typeof SDK) => (...args) => Promise<T>;
    successMessage?: string;
    onSuccess?: (res) => any;
    failureMessage?: string;
    onFailed?: (error: any) => any;
    throwErrors?: boolean;
  },
  defaultResponse: any = {}
) => {
  const [hasError, setHasError] = useStateIfMounted(false);
  const [response, setResponse] = useStateIfMounted(defaultResponse as T);
  const [isProcessing, setIsProcessing] = useStateIfMounted(false);
  const dispatch = useDispatch();

  const doAction = async (...args) => {
    try {
      setIsProcessing(true);
      let res = await config.action(SDK)(...args);
      setResponse(res);
      setIsProcessing(false);
      setHasError(false);
      config.successMessage && message.success(config.successMessage);
      config.onSuccess && config.onSuccess(res);
      return res;
    } catch (e) {
      setHasError(true);
      setIsProcessing(false);
      showErrorMessage(e, config.failureMessage);
      config.onFailed && config.onFailed(e);
      if (config.throwErrors) {
        throw e;
      }
    }
  };
  return {
    response,
    isProcessing,
    hasError,
    doAction,
    dispatch,
  };
};

export const useSDKWithRemotePagination = <T extends unknown>(
  callFunction: (
    sdk: typeof SDK
  ) => (
    query: any,
    paginationOptions: PaginatedOptions
  ) => Promise<PaginatedResults<T>>,
  query: any,
  dependencies: any[] = [],
  {
    pageSize: initialPageSize = 20,
    currentPage: initialStartPage = 1,
  }: {
    pageSize?: number;
    currentPage?: number;
  },
  disable?: boolean
) => {
  const [paginationState, setPaginationState] = useSimpleState({
    totalItems: 0,
    pageSize: initialPageSize,
    currentPage: initialStartPage,
  });

  const [hasError, setHasError] = useState(false);
  const [data, setData] = useState([] as T[]);
  const [isLoading, setLoading] = useState(true);
  const [reloadCounter, setReloadCounter] = useState(0);
  const reload = () => {
    setPaginationState({
      currentPage: 1,
    });
    setReloadCounter(reloadCounter + 1);
  };
  useEffect(() => {
    setLoading(true);
    const callAPI = async () => {
      let d = await callFunction(SDK).bind(SDK)(query, {
        offset: (paginationState.currentPage - 1) * paginationState.pageSize,
        limit: paginationState.pageSize,
      });
      setPaginationState({
        totalItems: d.totalDocs,
      });
      setData(d.docs);
      setLoading(false);
    };
    if (!disable) {
      callAPI()
        .then((d) => {
          setHasError(false);
        })
        .catch((e) => {
          setHasError(true);
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...dependencies,
    reloadCounter,
    paginationState.pageSize,
    paginationState.currentPage,
  ]);
  return {
    paginationState,
    setPaginationState,
    data,
    reload,
    isLoading,
    setData,
  };
};

interface iSDKActionConfig<T> {
  action: (sdk: typeof SDK) => (...args) => Promise<T>;
  successMessage?: string;
  onSuccess?: (res) => any;
  failureMessage?: string;
  onFailed?: (error: any) => any;
  actionDependencies?: any[];
  defaultResponse?: any;
  throwError?: boolean;
}

export const processServerError = (e, defaultErrorMessage?: string) => {
  const failureMessage =
    (e as any)?.response?.data?.message ||
    defaultErrorMessage ||
    (e as any)?.message;

  return failureMessage;
};

export const useSDKActionWithDeps = <T extends unknown>(
  configGenerator: () => iSDKActionConfig<T>,
  dependencies: any[]
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const config = useMemo(configGenerator, dependencies);
  const [hasError, setHasError] = useStateIfMountedX(false);
  const [response, setResponse] = useStateIfMountedX(
    (typeof config.defaultResponse === "undefined"
      ? {}
      : config.defaultResponse) as T
  );
  const [isProcessing, setIsProcessing] = useStateIfMountedX(false);

  const doAction: (...args: any[]) => Promise<T | undefined> = useCallback(
    async (...args) => {
      try {
        setIsProcessing(true);
        let res = await config.action(SDK)(...args);
        setResponse(res);
        setIsProcessing(false);
        setHasError(false);
        config.successMessage && message.success(config.successMessage);
        config.onSuccess && config.onSuccess(res);
        return res;
      } catch (e) {
        setHasError(true);
        setIsProcessing(false);
        const failureMessage = processServerError(e, config?.failureMessage);
        config.failureMessage && message.error(failureMessage);
        config.onFailed && config.onFailed(e);
        if (config.throwError) {
          throw e;
        }
      }
    },
    [setHasError, setIsProcessing, setResponse, config]
  );

  return {
    response,
    isProcessing,
    hasError,
    doAction,
  };
};
