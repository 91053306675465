import { SDK } from "@sdk";

import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { newsfeedItemDefaultQueryConfig } from "./newsfeed-items-default-query";
import { NewsfeedItemsSlice } from "./newsfeed-items.slice";
import { iNewsFeedItem } from "@sdk/services/newsfeed/newsfeed-models";
import { selectWidget } from "../session/session.selectors";

export const loadAllNewsfeedItems = (
  store: Store<iStore>,
  forceReload?: boolean
) =>
  loadNewsfeedItemsQuery(newsfeedItemDefaultQueryConfig, "all")(
    store,
    forceReload
  );

export const loadAllNewsfeedItemsByTypeId = (newsFeedTypeId: string) => (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  const widget = selectWidget(store.getState());
  return loadNewsfeedItemsQuery(
    {
      query: { newsFeedTypeId, organizationId: widget?.organizationId },
      options: newsfeedItemDefaultQueryConfig.options,
    },
    JSON.stringify({ newsFeedTypeId })
  )(store, forceReload);
};

export const {
  loadEntityQueries: loadNewsfeedItemsQuery,
  loadEntityById: loadNewsfeedItemById,
  loadEntitiesByIds: bulkLoadNewsfeedItemsByIds,
} = CreateHelpersForEntity<iStore, iNewsFeedItem>({
  sliceName: "newsfeedItems",
  slice: NewsfeedItemsSlice,
  isPaginatedQueries: true,
  loadEntityQueryProvider: (query) => SDK.queryNewsFeedItems(query),
  loadEntityByIdProvider: (entityId: string) => SDK.getNewsfeedItem(entityId),
  fetchPageSize: 100,
  loadEntityByIdsProvider: (newsfeedItemIds: string[]) =>
    (async () => {
      const newsfeedItems = await Promise.all(
        newsfeedItemIds.map((newsfeedItemId) =>
          SDK.getNewsfeedItem(newsfeedItemId)
        )
      );
      return newsfeedItems;
    })(),
});
