import { useCallback, useEffect, useMemo, useState } from "react";
import { SetLocalStorageItemSafely } from "./set-local-storage-item";

export const useLocalStorageStore = <T extends unknown>(
  key: string,
  initialValue: T,
  disableReadingInitialValue?: boolean
) => {
  const type = useMemo(() => {
    switch (typeof initialValue) {
      case "string": {
        return "STRING";
      }
      case "number": {
        return "NUMBER";
      }
      case "object": {
        return "JSON";
      }
      case "boolean": {
        return "BOOLEAN";
      }
      default: {
        return "STRING";
      }
    }
  }, [initialValue]);

  const localStorageAccessAvailable = useMemo(() => {
    try {
      if (localStorage.getItem(key)) {
        //
      }
      return true;
    } catch (e) {
      console.log("Third Party Storage is blocked");
      return false;
    }
  }, [key]);

  const initialLocalStateValue = useMemo(() => {
    if (disableReadingInitialValue) {
      return initialValue;
    }
    const value = localStorageAccessAvailable
      ? window.localStorage.getItem(key) || ""
      : "";
    if (type === "JSON") {
      try {
        return JSON.parse(value) || initialValue;
      } catch (e) {
        return initialValue;
      }
    } else if (type === "NUMBER") {
      return Number(value) || initialValue;
    } else if (type === "BOOLEAN") {
      return Boolean(value === "TRUE");
    }
    return String(value || initialValue);
  }, [
    disableReadingInitialValue,
    initialValue,
    key,
    localStorageAccessAvailable,
    type,
  ]);

  const [value, setValue] = useState<T>(initialLocalStateValue);

  useEffect(() => {
    if (localStorageAccessAvailable) {
      if (type === "JSON") {
        try {
          SetLocalStorageItemSafely(key, JSON.stringify(value));
          return;
        } catch (e) {
          SetLocalStorageItemSafely(key, JSON.stringify(initialValue));
        }
      } else if (type === "NUMBER") {
        SetLocalStorageItemSafely(key, String(value));
      } else if (type === "BOOLEAN") {
        SetLocalStorageItemSafely(key, value ? "TRUE" : "FALSE");
      } else if (type === "STRING") {
        SetLocalStorageItemSafely(key, value as string);
      } else {
        SetLocalStorageItemSafely(key, String(value));
      }
    }
  }, [initialValue, key, localStorageAccessAvailable, type, value]);
  return [value, setValue] as [typeof value, typeof setValue];
};
