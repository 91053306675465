import { selectAudioMuteState } from "store/modules/app-state/app-state.selectors";
import { store } from "store/store";

export class AudioNotificationsService {
  static playAudio(type: AvailableAudios) {
    const audioMuteState = selectAudioMuteState(store.getState());
    if (!audioMuteState) {
      try {
        (document.getElementById(type) as any).play().catch((e) => {
          // Ignore
        });
      } catch (e) {
        // Ignore
      }
    }
  }
}

export enum AvailableAudios {
  NEW_MESSAGE = "CC_message",
}
