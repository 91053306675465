import { store } from "store/store";
import { buildUrl } from "utils/build-url";

export const ReloadWidgetPage = () => {
  const BASE_URL = window.location.protocol + "//" + window.location.host;
  // + (!(window.location.port === "") ? ":" + window.location.port : "");

  window.location.href = buildUrl(BASE_URL, {
    widgetId: store.getState().session.widgetConfigurations?.id,
  });
};
