import { ParentWindowService } from "@parent-window/parent-window";
import { isThirdPartyBlocked } from "utils/is-thirdparty-storage-blocked";
import { parseQuery } from "utils/parse-query";

const { widgetId } = (() => {
  // eslint-disable-next-line no-useless-escape
  const parsedUrl = parseQuery(window.location.href.replace(/^[^\?]+\??/, ""));
  const paths = window.location.pathname.split("/");
  // Todo: Check WIdget ID using isValidateWidgetId
  const widgetId: string =
    parsedUrl.widgetId || (window as any).WIDGET_ID || paths[1] || undefined;
  return { widgetId };
})();

export class ReferenceSignalHandler {
  private static referer: string | null = isThirdPartyBlocked()
    ? null
    : window.localStorage.getItem(`referer:${widgetId} `);
  static markReferer(referer: string) {
    this.referer = referer;
    try {
      window.localStorage.setItem(`referer:${widgetId} `, referer);
    } catch (e) {
      console.log("Third Party Storage blocked");
    }
  }

  static getReferer() {
    if (ParentWindowService?.currentPageDetails?.url && this.referer) {
      if (
        new URL(ParentWindowService.currentPageDetails.url).hostname !==
        new URL(this.referer).hostname
      ) {
        return this.referer;
      }
      return undefined;
    }

    return this.referer || undefined;
  }
}
