import en_common from "./locales/en/common.json";

export const ReactI18NextConfig = {
  // resources: {
  //   // en: { common: en_common },
  // },
  // debug: true,
  lng: "en", // if you're using a language detector, do not define the lng option
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
  ns: "common",
  defaultNS: "common",
  backend: {
    loadPath: "/locales/{{lng}}/{{ns}}.json",
    queryStringParams: { v: "0.0.2" },
  },
};
