import { SDK } from "@sdk";
import { iMessageSenderType } from "@sdk/services/models";
import { Store } from "redux";
import { iStore } from "../../store.model";
import {
  addNewMessage,
  setMessagesLoadingErrorStatus,
  setMessagesLoadingStatus,
  setMessagesRes,
} from "./message.actions";

export const addDummyMessage = (
  conversationId: string,
  store: Store<iStore>
) => {
  store.dispatch(
    addNewMessage({
      from: { senderType: iMessageSenderType.USER, id: "043dd0-c115d" },
      metaData: { sentAt: 1603215972238, deliveredAt: 1603215972238 },
      connectionId: "xxxx",
      connectionType: "EMAIL",
      organizationId: "043dd0",
      attachments: [],
      references: [],
      contactId: conversationId,
      conversationId: conversationId,
      message: `Test Message - ${new Date().toString()}`,
      id: `043dd0-${new Date().valueOf()}`,
    })
  );
};

export const refreshAllLoadedConversationMessages = async (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  // Conversations that have messages loaded
  const conversations = Object.keys(
    store.getState().messages.byConversations || {}
  );

  for (const conversationId of conversations) {
    loadMessages(conversationId)(store, true);
  }
};

export const loadMessages = (conversationId: string) => async (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  const state = store.getState().messages.byConversations[conversationId];
  if (!state || forceReload || state.hasError) {
    store.dispatch(
      setMessagesLoadingStatus({ conversationId, isLoading: true })
    );
    try {
      if (conversationId.includes("TEMP")) {
        return;
      }
      const res = await SDK.getMessagesByConversationId(conversationId);
      store.dispatch(
        setMessagesRes({ conversationId, messagesResults: res, reset: true })
      );
    } catch (e) {
      store.dispatch(
        setMessagesLoadingErrorStatus({ conversationId, hasError: true })
      );
      throw e;
    }
  }
};

const MESSAGES_PAGE_SIZE = 100;

export const loadMoreMessages = (conversationId: string) => async (
  store: Store<iStore>
) => {
  const state = store.getState().messages.byConversations[conversationId];
  if (state && !state.isFetching && state.fetchedPages > 0) {
    store.dispatch(
      setMessagesLoadingStatus({ conversationId, isLoading: true })
    );
    try {
      if (conversationId.includes("TEMP")) {
        return;
      }
      const res = await SDK.getMessagesByConversationId(
        conversationId,
        MESSAGES_PAGE_SIZE,
        state.fetchedPages * MESSAGES_PAGE_SIZE
      );
      store.dispatch(setMessagesRes({ conversationId, messagesResults: res }));
    } catch (e) {
      store.dispatch(
        setMessagesLoadingErrorStatus({ conversationId, hasError: true })
      );
      throw e;
    }
  }
};
