import { message } from "antd";
import _axios, {
  AxiosRequestConfig,
  AxiosResponse as _AxiosResponse,
} from "axios";


import { uuidv4 } from "utils/generate-uuid";

// Check https://stackoverflow.com/questions/55868338/how-to-avoid-sending-multiple-duplicate-ajax-requests-in-axios

const pendingRequests = {};
const requestCaches: {
  [key: string]: {
    timestamp: number;
    response: any;
  };
} = {};

const rateLimits: {
  [key: string]: {
    timestamps: number[];
  };
} = {};

const cacheTimeout = 200; // 200 Milliseconds
const rateLimitPer10Seconds = 10;

function getUniqueUrl(config: AxiosRequestConfig) {
  // you can set the rule based on your own requirement
  const bodyString =
    typeof config.data === "string" ? config.data : JSON.stringify(config.data);
  const formData = config.data instanceof FormData ? `&${uuidv4()}` : "";

  return (
    config.url +
    "&" +
    config.method +
    "&" +
    config.headers.Authorization +
    "&" +
    JSON.stringify(config.params) +
    "&" +
    bodyString +
    formData
  );
}

const rateLimitUtils = {
  getUniqueUrl: getUniqueUrl,
  getRateLimitPass: function (config: AxiosRequestConfig) {
    const uniqueUrl = this.getUniqueUrl(config);
    if (rateLimits[uniqueUrl]) {
      rateLimits[uniqueUrl].timestamps.push(Date.now());
      if (rateLimits[uniqueUrl].timestamps.length > rateLimitPer10Seconds) {
        return false;
      }
      // * Could Optimize this
      rateLimits[uniqueUrl].timestamps = rateLimits[
        uniqueUrl
      ].timestamps.filter((timestamp) => timestamp + 10000 > Date.now());
    } else {
      rateLimits[uniqueUrl] = {
        timestamps: [Date.now()],
      };
    }
    return true;
  },
};

const cacheUtils = {
  getUniqueUrl: getUniqueUrl,
  isCached: function (config) {
    const uniqueUrl = this.getUniqueUrl(config);
    return (
      requestCaches[uniqueUrl] !== undefined &&
      requestCaches[uniqueUrl].timestamp + cacheTimeout > Date.now()
    );
  },
  isPending: function (config) {
    const uniqueUrl = this.getUniqueUrl(config);
    if (!pendingRequests[uniqueUrl]) {
      pendingRequests[uniqueUrl] = [config];
      return false;
    } else {
      pendingRequests[uniqueUrl].push(config);
      return true;
    }
  },
  setCachedResponse: function (config, response) {
    const uniqueUrl = this.getUniqueUrl(config);
    requestCaches[uniqueUrl] = {
      timestamp: Date.now(),
      response,
    };
    if (pendingRequests[uniqueUrl]) {
      pendingRequests[uniqueUrl].forEach((configItem) => {
        configItem.isFinished = true;
      });
      delete pendingRequests[uniqueUrl];
    }
  },
  getError: function (config) {
    const skipXHRError = new Error("skip") as any;
    skipXHRError.isSkipXHR = true;
    skipXHRError.requestConfig = config;
    return skipXHRError;
  },
  getCachedResponse: function (config) {
    const uniqueUrl = this.getUniqueUrl(config);
    const statusCode = requestCaches[uniqueUrl].response.status;
    if (statusCode < 199 || statusCode > 299) {
      throw requestCaches[uniqueUrl].response;
    }
    return requestCaches[uniqueUrl].response;
  },
};

// This should be the *last* request interceptor to add
_axios.interceptors.request.use(function (config) {
  if (cacheUtils.isCached(config)) {
    const error = cacheUtils.getError(config);
    throw error;
  }
  if (cacheUtils.isPending(config)) {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if ((config as any).isFinished) {
          clearInterval(interval);
          const error = cacheUtils.getError(config);
          reject(error);
        }
      }, 200);
    });
  } else {
    // the head of cacheable requests queue, get the response by http request

    if (!rateLimitUtils.getRateLimitPass(config)) {
      throw new Error("API Rate Limit Exceeded");
    }

    return config;
  }
});

// This should be the *first* response interceptor to add
_axios.interceptors.response.use(
  function (response) {
    cacheUtils.setCachedResponse(response.config, response);
    return response;
  },
  function (error) {
    /* recover from error back to normality
     * but this time we use an cached response result
     **/
    if (error.isSkipXHR) {
      return cacheUtils.getCachedResponse(error.requestConfig);
    }
    if (!error.response) {
      console.log("unknown Axios Exception", error, error.requestConfig);
      if (error.message === "Request aborted") {
        message.warning("Warning: Unstable Internet Connection");
        return Promise.reject(error);
      } else if (error.message.includes("timeout of")) {
        message.warning("Warning: Unstable Internet Connection");
        return Promise.reject(error);
      } else if (
        error.message.includes("Network Error") ||
        error.stack.includes("Error: Network Error")
      ) {
        console.log('"Network Error" identified');
        message.warning("Warning: Unstable Internet Connection");
        // Todo: Network error, maybe just reload the page
        return Promise.reject(error);
      } else {
        //* The following will throw error
        cacheUtils.setCachedResponse(error.response.config, error);
        return Promise.reject(error);
      }
    }
    cacheUtils.setCachedResponse(error.response.config, error);
    return Promise.reject(error);
  },
);

const axios = _axios as any as typeof _axios;

export type AxiosResponse<T> = _AxiosResponse<T>;

export { axios };
