import { iArticle } from "@sdk/services/knowledge-base/knowledge-base-models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { articleDefaultQueryConfig } from "./article-default-query";

export const {
  slice: ArticlesSlice,
  reducers: ArticlesSliceReducer,
  actions: {
    setEntityQueryResults: setArticlesQueryResults,
    setEntity: setArticle,
    setEntities: setArticles,
    patchEntity: patchArticle,
    addNewEntity: addNewArticle,
    setEntityFetchingStatus: setArticleFetchingStatus,
    setEntitiesFetchingStatus: setArticlesFetchingStatus,
    setEntityFailedStatus: setArticleFailedStatus,
    setEntityQueryError: setArticlesQueryError,
    resetQuery: resetArticlesQuery,
  },
} = CreateEntitySlice<iArticle>({
  sliceName: "articles",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...articleDefaultQueryConfig,
        alias: "all",
      };
    }
  },
});
