import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";
import { iNewsFeedItem } from "@sdk/services/newsfeed/newsfeed-models";

export const {
  selectQueryMap: selectNewsfeedItemQueryMap,
  selectEntityMap: selectNewsfeedItemsMap,
  selectQueryMapAll: selectNewsfeedItemsMapAll,
  selectQueryAllList: selectNewsfeedItemsAllList,
  selectAllEntities: selectAllNewsfeedItems,
  selectAllEntitiesQuery: selectAllNewsfeedItemsQuery,
  selectEntityById: selectNewsfeedItemById,
  selectEntityQuery: selectNewsfeedItemsQuery,
} = CreateSelectorForEntities<iStore, iNewsFeedItem>({
  sliceName: "newsfeedItems",
});

export const selectNewsfeedItemsByType = (newsfeedTypeId: string) => (store) =>
  selectNewsfeedItemsQuery(JSON.stringify({ newsFeedTypeId: newsfeedTypeId }))(
    store
  )?.list;
