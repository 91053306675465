import { ParentWindowService } from "@parent-window/parent-window";
import { Store } from "@reduxjs/toolkit";
import { MessageAttachmentTypes } from "@sdk/services/models";
import filter from "lodash/filter";
import find from "lodash/find";
import last from "lodash/last";
import { selectConversationsQuery } from "store/modules/conversations/conversations.selectors";
import { loadMessages } from "store/modules/messages/messages.helpers";
import { selectMessageByConversationId } from "store/modules/messages/messages.selectors";
import { selectWidget } from "store/modules/session/session.selectors";
import { getUserById } from "store/modules/users/users.helpers";

export const NotifyUnreadMessages = (store: Store) => {
  const allConversation =
    selectConversationsQuery("min")(store.getState())?.list || [];
  const allActiveConversation = filter(allConversation, { status: "OPEN" });
  // console.log("allActiveConversation", allActiveConversation);

  const sendLastMessageToParent = async (conversationId: string) => {
    const widgetConfig = selectWidget(store.getState());

    let organizationName = selectWidget(store.getState())?.configurations
      ?.locale?.custom?.TEAM_NAME;
    if (widgetConfig?.organizationId === "fc89e0") {
      organizationName = "Click Connector";
    }

    const messages = await (async () => {
      await loadMessages(conversationId!)(store);
      return (
        selectMessageByConversationId(conversationId!)(store.getState())
          ?.data || []
      );
    })();

    const lastMessageFromUser = last(
      messages.filter((message) => message.from.senderType === "USER")
    )!;

    if (!lastMessageFromUser) {
      console.log(
        "Something went wrong while fetching messages for notification"
      );
      return;
    }

    const user = await getUserById(lastMessageFromUser.from.id, store);

    const coverImage = (() => {
      const coverImageAttachment = find(lastMessageFromUser.attachments, {
        type: MessageAttachmentTypes.GREETINGS_COVER,
      });

      if (coverImageAttachment) {
        return coverImageAttachment.payload.coverImage;
      }
    })();

    const widget = selectWidget(store.getState());

    const { userName, userAvatar } = (() => {
      const userName = user?.data?.firstName;
      const userAvatar = user?.data?.avatar;

      if (
        lastMessageFromUser.from.id === "CC" ||
        lastMessageFromUser.from.id === "cc" ||
        lastMessageFromUser.from.id === "SYSTEM"
      ) {
        return {
          userName: widget?.configurations.chatBotProfile?.name || userName,
          userAvatar:
            widget?.configurations.chatBotProfile?.avatar || userAvatar,
        };
      }
      return {
        userName,
        userAvatar,
      };
    })();

    // console.log('conversationId', conversationId)

    ParentWindowService.sendMessage({
      type: "CC_UNREAD_MESSAGE",
      data: {
        message: lastMessageFromUser.message,
        conversationId: conversationId,

        automaticallyOpenChatWidgetOnNewMessages:
          widgetConfig?.configurations
            ?.automaticallyOpenChatWidgetOnNewMessages,
        organizationName,
        userAvatar,
        userName,
        coverImage,
      },
    });
  };

  for (const activeConversation of allActiveConversation) {
    if (
      activeConversation.metaData.readWatermarks.client <
      activeConversation.metaData.lastMessage.team
    ) {
      // Send Unread Message to Parent
      sendLastMessageToParent(activeConversation.id!);
      break;
    }
  }
};
