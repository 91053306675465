/**
 * Truncates the text content of an HTML string to a specified length.
 * @param html The HTML string to truncate.
 * @param maxLength The maximum number of characters to keep in the text content.
 * @returns The truncated HTML string.
 */
export function truncateHTML(html: string, maxLength: number): string {
  const div = document.createElement("div");
  div.innerHTML = html;

  // Calculate total text length
  const totalTextLength = div.innerText.length;

  // Early return if maxLength is greater than or equal to total text length
  if (maxLength >= totalTextLength) {
    return html; // No need to truncate
  }

  let currentLength = 0;

  const truncateNode = (node: Node): Node | null => {
    // Safety check to prevent further processing if maxLength is exceeded
    if (currentLength >= maxLength) return null;

    // Process text nodes
    if (node.nodeType === Node.TEXT_NODE) {
      const textContent = node.textContent || "";
      if (currentLength + textContent.length > maxLength) {
        node.textContent = textContent.slice(0, maxLength - currentLength);
      }
      currentLength += textContent.length;
      return node;
    }

    // Process element nodes
    if (node.nodeType === Node.ELEMENT_NODE) {
      const clone = node.cloneNode(false);
      let child = node.firstChild;

      while (child && currentLength < maxLength) {
        const truncatedChild = truncateNode(child);
        if (truncatedChild) {
          clone.appendChild(truncatedChild);
        }
        child = child.nextSibling;

        // Safety check to avoid infinite loops
        if (child === node) break;
      }
      return clone;
    }

    return null;
  };

  const truncatedContent = truncateNode(div);
  return truncatedContent ? (truncatedContent as HTMLElement).innerHTML : "";
}

//   // Example usage
//   const htmlString = `<p>Hello, <strong>world!</strong> This is a test of <em>HTML truncation</em>.</p>`;
//   const truncatedHTML = truncateHTML(htmlString, 15);
//   console.log(truncatedHTML); // Output: "<p>Hello, <strong>world!</strong> Th</p>"
