import { iNewsFeedItem } from "@sdk/services/newsfeed/newsfeed-models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { newsfeedItemDefaultQueryConfig } from "./newsfeed-items-default-query";

export const {
  slice: NewsfeedItemsSlice,
  reducers: NewsfeedItemsSliceReducer,
  actions: {
    setEntityQueryResults: setNewsfeedItemsQueryResults,
    setEntity: setNewsfeedItem,
    setEntities: setNewsfeedItems,
    patchEntity: patchNewsfeedItem,
    addNewEntity: addNewNewsfeedItem,
    setEntityFetchingStatus: setNewsfeedItemFetchingStatus,
    setEntitiesFetchingStatus: setNewsfeedItemsFetchingStatus,
    setEntityFailedStatus: setNewsfeedItemFailedStatus,
    setEntityQueryError: setNewsfeedItemsQueryError,
    resetQuery: resetNewsfeedItemsQuery,
  },
} = CreateEntitySlice<iNewsFeedItem>({
  sliceName: "newsfeedItems",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...newsfeedItemDefaultQueryConfig,
        alias: "all",
      };
    }
  },
});
