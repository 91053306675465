import { MessagesSliceActions } from "./messages.store";

export const {
  setMessagesRes,
  addNewMessage,
  removeLastMessage,
  resetUnreadCount,
  setMessagesLoadingStatus,
  setMessagesLoadingErrorStatus,
  patchMessage,
} = MessagesSliceActions;
