import { ParentWindowService } from "@parent-window/parent-window";
import { SDK } from "@sdk";
import {
  iChatBotFlowConfig,
  iMessageNode,
} from "@sdk/services/chat-bot-models";
import { iCampaign } from "@sdk/services/models";
import { push } from "connected-react-router";

import last from "lodash/last";
import reverse from "lodash/reverse";
import { Store } from "redux";
import { GenerateSimpleCampaign } from "store/modules/app-state/helpers/generate-simple-campaign";
import { addNewConversation } from "store/modules/conversations/conversations.slice";
import { setMessagesRes } from "store/modules/messages/message.actions";
import { selectWidget } from "store/modules/session/session.selectors";
import { selectRandomUser } from "store/modules/users/users.selectors";
import {
  generateConversationWithGreetings,
  GenerateSimpleCampaignMessage,
  iLocalConversationMessage,
} from "./local-conversation-generator";
import { CampaignsController } from "store/modules/app-state/campaigns.controller";
import { getRandomUser } from "store/modules/users/users.helpers";

const MESSAGES_PAGE_SIZE = 100;
export const initLocalConversation = async (
  store: Store,
  options: {
    messages?: iLocalConversationMessage[];
    notifyUsers?: boolean;
    userId?: string;
  } = {}
) => {
  // console.log("initLocalCampaignConversation",options);
  const dispatch = store.dispatch;

  const widget = selectWidget(store.getState());

  if (
    !options.messages &&
    widget?.configurations?.conversationFlow?.greetingsMessage?.chatBotId
  ) {
    const chatBotId =
      widget?.configurations?.conversationFlow?.greetingsMessage?.chatBotId;

    const startNode = await CampaignsController.getChatbotFirstNodeConfig(
      widget.id!,
      chatBotId
    );

    if (startNode) {
      const campaign = GenerateSimpleCampaign(
        {
          id: "DEFAULT_GREETINGS_CHAT_BOT",
          text: startNode.text!,
          // quickReplies: startNode.quickButtons.map((item) => ({
          //   label: item.label,
          //   action: "MESSAGE",
          //   data: {},
          // })),
          coverImage: undefined,
          chatBotId: chatBotId,
          options: {
            additionalMessageData: {
              botContext: startNode,
            },
          },
        } // autoTriggerConfig.coverImage
      );
      return await initLocalCampaignConversation(campaign, {
        muteNotification: !options.notifyUsers,
      })(store);
    }
  }

  // Todo
  const { conversation, messages } = generateConversationWithGreetings(
    options.messages
      ? { messages: options.messages }
      : widget?.configurations.conversationFlow.greetingsMessage!,
    options.userId ? { userId: options.userId } : undefined,
    store
  );
  dispatch(addNewConversation(conversation));
  try {
    dispatch(
      setMessagesRes({
        conversationId: conversation.id!,
        messagesResults: {
          docs: reverse(messages),
          totalDocs: messages.length,
          limit: MESSAGES_PAGE_SIZE,
          offset: 0,
        } as any,
        reset: true,
      })
    );
  } catch (e) {
    // Ignore
  }
  if (options.notifyUsers) {
    ParentWindowService.sendNewMessageNotification(messages[0], {
      muteNotification: true,
      resetCount: true,
    });
    store.dispatch(push(`/conversations/${conversation.id}`));
  }

  return { conversation };
};

export const initLocalCampaignConversation = (
  campaign: iCampaign,
  options: { userId?: string; muteNotification?: boolean } = {}
) => async (store: Store) => {
  // console.log("initLocalCampaignConversation",campaign);
  const dispatch = store.dispatch;
  const widget = selectWidget(store.getState());

  const hasBotProfile = widget?.configurations?.chatBotProfile?.name;

  const campaignUser =
    options.userId ||
    campaign?.data?.options?.userId ||
    (hasBotProfile ? "cc" : (await getRandomUser(store))?.id);

  let { conversation, messages } = generateConversationWithGreetings(
    {
      text: campaign.data.message!,
      options: campaign.data.quickReplies || [],
      coverImage: campaign.data.offerImageUrl,
      messageData: campaign.data.options?.additionalMessageData,
    },
    {
      userId: campaignUser,
      chatBotId: campaign.data.chatBotId,
      messageCampaignId: campaign.data.messageCampaignId,
    },
    store
  );

  if (campaign.data.options?.additionalMessages) {
    messages = [
      ...messages,
      ...campaign.data.options.additionalMessages.map((item) =>
        GenerateSimpleCampaignMessage(
          {
            message: item.message,
            userId: campaignUser,
            conversationId: conversation.id!,
          },
          store
        )
      ),
    ];
  }

  dispatch(addNewConversation(conversation));
  try {
    dispatch(
      setMessagesRes({
        conversationId: conversation.id!,
        messagesResults: {
          docs: messages,
          totalDocs: messages.length,
          limit: 50,
          offset: 0,
        } as any,
        reset: true,
      })
    );
  } catch (e) {
    // Ignore
  }
  ParentWindowService.sendNewMessageNotification(last(messages)!, {
    muteNotification: true,
    resetCount: true,
  });
  store.dispatch(push(`/conversations/${conversation.id}`));

  return {
    conversation,
    messages,
    campaignUser,
  };
};
