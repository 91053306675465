import { iUser } from "@sdk/services/models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { usersDefaultQueryConfig } from "./users-default-query";

export const {
  slice: UsersSlice,
  reducers: UsersSliceReducer,
  actions: {
    setEntityQueryResults: setUsersQueryResults,
    setEntity: setUser,
    setEntities: setUsers,
    patchEntity: patchUser,
    addNewEntity: addNewUser,
    setEntityFetchingStatus: setUserFetchingStatus,
    setEntitiesFetchingStatus: setUsersFetchingStatus,
    setEntityFailedStatus: setUserFailedStatus,
    setEntityQueryError: setUsersQueryError,
    resetQuery: resetUsersQuery,
  },
} = CreateEntitySlice<iUser>({
  sliceName: "users",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...usersDefaultQueryConfig,
        alias: "all",
      };
    }
  },
});
