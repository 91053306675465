import _storage from "redux-persist/lib/storage";

async function noop() {
  return {};
}

export const temporaryStorage = {
  getItem: noop,
  setItem: noop,
  removeItem: noop,
};
