import { EntityServices } from "@sdk/utils/entity.service";
import { axios, AxiosResponse } from "../axios";
import { iDynamicForm } from "./dynamic-forms-model";

export class DynamicFormsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  dynamicForms = Object.assign(
    new EntityServices<iDynamicForm>(this.config, "dynamic-forms"),
    {
      submitResponse: async (body: any) => {
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/chat-widget/sessions/forms/submit-response`,
          body,
          {
            headers: {
              Authorization: this.config.token,
            },
          }
        );
        return results.data;
      },
      getPublicDynamicFormConfig: async (id: string) => {
        const results: AxiosResponse<iDynamicForm> = await axios.get(
          this.config.basePath + `/dynamic-forms/public/${id}`,
          {
            headers: {},
          }
        );
        return results.data;
      },
    }
  );
}
