import {
  iCampaign,
  iCampaignOptions,
  iChatFlowConfig_GreetingsOption,
  TriggerConditionPresets,
} from "@sdk/services/models";

export const GenerateSimpleCampaign = ({
  id,
  text,
  quickReplies,
  coverImage,
  chatBotId,
  messageCampaignId,
  options,
}: {
  id: string;
  text: string;
  quickReplies?: iChatFlowConfig_GreetingsOption[];
  coverImage?: string;
  chatBotId?: string;
  messageCampaignId?: string;
  options?: iCampaignOptions;
}): iCampaign => ({
  id: id,
  isEnabled: true,
  data: {
    offerImageUrl: coverImage,
    message: text,
    quickReplies,
    triggerCondition: {
      preset: TriggerConditionPresets.NONE,
      data: {
        query: [],
        secondsToDelay: 5000,
        forUtmTags: [],
      },
    },
    chatBotId,
    messageCampaignId,
    options,
  },
});
