export function parseQuery(query: string) {
  // eslint-disable-next-line no-new-object
  const Params: any = new Object();
  if (!query) {
    return Params;
  } // return empty object
  const Pairs = query.split(/[;&]/);
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < Pairs.length; i++) {
    const KeyVal = Pairs[i].split("=");
    if (!KeyVal || KeyVal.length !== 2) {
      continue;
    }
    const key = unescape(KeyVal[0]);
    let val = unescape(KeyVal[1]);
    val = val.replace(/\+/g, " ");
    Params[key] = val;
  }
  return Params;
}
