import { iWidget, LauncherPosition } from "@sdk/services/models";

export const defaultWidget: iWidget = {
  organizationId: "xxx1",
  connectionId: "xxx1",
  data: {
    label: "Primary Widget",
  },
  configurations: {
    locale: {
      language: "en",
      custom: {},
    },
    appearance: {
      colorPallet: {
        primary: "#0f0fb2",
        // primary: "#263238",
        // primary: "#388e3c",
      },
      launcherPosition: LauncherPosition.RIGHT_BOTTOM,
    },
    conversationFlow: {
      greetingsMessage: {
        text: "Hey there 👋, How can we help you?",
        options: [
          {
            label: "I have a question",
            action: "MESSAGE",
            data: {},
          },
          {
            label: "Book a demo",
            action: "MESSAGE",
            data: {},
          },
          {
            label: "I want to continue",
            action: "MESSAGE",
            data: {},
          },
        ],
      },
      preChatForm: {
        isEnabled: true,
        fields: ["NAME", "EMAIL"],
      },
      chatRatings: {
        enabled: true,
        allowAgentsToPrompt: false,
      },
    },
    faqCollections: [],
  },
  metaData: {
    isActive: true,
    isDeleted: false,
    createdAt: new Date().getTime(),
  },
};
