export const extractContactDataFromDataObj = (data: any) => {
  // Extract Name
  let firstName =
    data.firstName ||
    data.firstname ||
    data.FIRST_NAME ||
    data.first_name ||
    data.FIRSTNAME ||
    data.FirstName ||
    data["First Name"] ||
    data["first name"] ||
    data["FIRST NAME"];

  let lastName =
    data.lastName ||
    data.lastname ||
    data.LAST_NAME ||
    data.last_name ||
    data.LASTNAME ||
    data.LastName ||
    data["Last Name"] ||
    data["last name"] ||
    data["LAST NAME"];

  if (!firstName) {
    const name =
      data.name ||
      data.Name ||
      data.NAME ||
      data.fullName ||
      data.FullName ||
      data.FULL_NAME ||
      data.full_name;
    if (name) {
      const nameParts: string[] = name.split(" ");
      firstName = nameParts[0];
      lastName = nameParts.slice(1).join(" ");
    }
  }
  // Extract Email
  const primaryEmail =
    data.primaryEmail ||
    data.PRIMARY_EMAIL ||
    data.PrimaryEmail ||
    data.primary_email ||
    data.email ||
    data.EMAIL ||
    data.eMail ||
    data.Email;

  // Extract  Phone
  return {
    ...(firstName ? { firstName } : {}),
    ...(lastName ? { lastName } : {}),
    ...(primaryEmail ? { primaryEmail } : {}),
  };
};
