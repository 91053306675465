import { Tooltip } from "antd";
import React from "react";

export const ToolTipX = ({
  content,
  children,
}: {
  content: string;
  children: React.ReactElement;
}) => {
  return <Tooltip title={content}>{children}</Tooltip>;
};
