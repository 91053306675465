import { ReloadWidgetPage } from "helpers/reload-widget-page";
import { buildUrl } from "utils/build-url";
import { ENV } from "../../config";
import { io, Socket } from "socket.io-client";
import { refreshAllLoadedConversationMessages } from "store/modules/messages/messages.helpers";
import { reWatchAdditionalEntities } from "store/re-watch-additional-entitities";

export class SocketConnector {
  static uuid = new Date().getTime().toString(); // To Identify each browser session
  static socket: Socket<any, any>;
  static connectionLogs: {
    message: string;
    time: string;
    isOnline: boolean;
  }[] = [];
  static lastToken: string;
  static firstToken: string;
  static lastDisconnectTime: number = 0;
  static store: any;

  static connect({
    token,
    organizationId,
    widgetId,
    existingSessionToken,
    store,
    isSandboxMode,
    sandboxConfig,
    socketQueryData,
  }: {
    token: string;
    organizationId: string;
    widgetId: string;
    existingSessionToken?: string;
    store: any;
    isSandboxMode?: boolean;
    sandboxConfig?: any;
    socketQueryData?: {
      device?: string;
      referer?: string;
      landedUrl?: string;
      landedPageTitle?: string;
    };
  }) {
    for (const key in socketQueryData) {
      if (socketQueryData[key] === undefined) {
        delete socketQueryData[key];
      }
    }
    // The following condition is added to support HMR
    if (
      this.lastToken !== token &&
      (!this.socket || (this.socket && this.socket.disconnected))
    ) {
      // const socketConnectionQuery = `namespace=user&accessToken=${token}&uuid=${this.uuid}&guest=true&organizationId=${organizationId}&widgetId=${widgetId}&existingSessionToken=${existingSessionToken}`;
      const autoConnect = true;
      if (SocketConnector.socket) {
        SocketConnector.socket.disconnect();
      }
      const socket = io(ENV.SOCKET_BASE_PATH, {
        query: {
          namespace: "user",
          accessToken: token,
          uuid: this.uuid,
          guest: "true",
          organizationId: organizationId,
          widgetId: widgetId,
          existingSessionToken: existingSessionToken!,
          isSandboxMode: isSandboxMode ? "true" : "",
          sandboxConfig,
          ...(socketQueryData || {}),
        },
        secure: window.location.protocol === "https:",
        autoConnect,
        transports: ["websocket"],
        rememberUpgrade: true,
        upgrade: false,
        reconnectionDelayMax: 2000,
        transportOptions: {
          pingInterval: 25000, // default - 25000
          pingTimeout: 60000, // default - 60000
        },
      });

      SocketConnector.socket = socket;
      SocketConnector.store = store;
      this.patchSocketListener();
      this.onConnected();
      this.lastToken = token;
    }
  }

  static disconnect() {
    if (SocketConnector.socket) {
      SocketConnector.socket.disconnect();
    }
  }

  private static patchSocketListener() {
    // Updated solution for socket.io-client 1.3.7
    // https://stackoverflow.com/questions/10405070/socket-io-client-respond-to-all-events-with-one-handler=
    const onevent = (this.socket as any).onevent;
    (this.socket as any).onevent = function (packet) {
      const args = packet.data || [];
      onevent.call(this, packet); // original call
      packet.data = ["*"].concat(args);
      onevent.call(this, packet); // additional call to catch-all
    };
  }

  private static onConnected() {
    const socket = SocketConnector.socket;

    socket.on("connect", () => {
      console.log("Connected", socket.id);
      if (this.connectionLogs.length !== 0) {
        refreshAllLoadedConversationMessages(SocketConnector.store, true).catch(
          (e) => {
            console.log(
              "Error while refreshing conversations - Socket Reconnected"
            );
          }
        );
        reWatchAdditionalEntities();
      }
      this.connectionLogs.push({
        message: "Connected",
        time: new Date().toString(),
        isOnline: window.navigator.onLine,
      });
    });

    socket.on("reconnect", () => {
      console.log("Reconnected", socket.id);
      this.connectionLogs.push({
        message: "Reconnected",
        time: new Date().toString(),
        isOnline: window.navigator.onLine,
      });

      // Reload All Messages
      refreshAllLoadedConversationMessages(SocketConnector.store, true).catch(
        (e) => {
          console.log(
            "Error while refreshing conversations - Socket Reconnected"
          );
        }
      );
      reWatchAdditionalEntities();
      // If it grater than 15 minutes, reload the page
      if (
        this.lastDisconnectTime &&
        this.lastDisconnectTime + 15 * 60 * 1000 < new Date().getTime()
      ) {
        ReloadWidgetPage();
        return;
      }
    });

    socket.on("disconnect", (reason) => {
      // Todo: Not sure at what time disconnected gets logged when pc goes to sleep
      // Probably need to have a timer that logs the last active time do the location reload logic above
      console.log("Disconnected", reason, socket.id);
      this.connectionLogs.push({
        message: "Disconnected",
        time: new Date().toString(),
        isOnline: window.navigator.onLine,
      });

      this.lastDisconnectTime = new Date().getTime();
    });

    socket.on("KNOCK_KNOCK", () => {
      console.log("KNOCK_KNOCK");
      socket.emit("YES_YES");
    });

    socket.on("RELOAD", () => {
      console.log("RELOAD");
      ReloadWidgetPage();
    });
  }

  static req(event: string, data) {
    // Todo: Got to re-check this.
    // The idea is to make a request to socket server and return the response through await interface
    return new Promise((resolve, reject) => {
      const maxTimeout = 5000;
      const timer = setTimeout(() => {
        throw new Error("Max Timeout Exceeded");
      }, maxTimeout);
      this.socket.emit(event, data, (response: any) => {
        clearTimeout(timer);
        resolve(response);
      });
    });
  }
}
