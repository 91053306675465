import classNames from "classnames";
import React from "react";

export const HomePageWidgetContainer = ({
  icon,
  title,
  subHeading,
  extraContent,
  noHeaderBorder,
  children,
  animationClass,
  containerClass
}: {
  icon?: JSX.Element;
  title?: string;
  subHeading?: string;
  extraContent?: JSX.Element;
  noHeaderBorder?: boolean;
  children;
  animationClass?: string;
  containerClass?: string;
}) => {
  return (
    //       className={`home-page-widget m-4 mt-0 p-3 rounded-lg animate ${
    // home-page-widget
    <div
      className={` bg-white p-4 m-4 mt-0 animate ${
        animationClass || "fade-in-right"
      }  bg-white ${containerClass || ''}`}
      style={{
        borderRadius: 10,
        boxShadow:
          "rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.06) 0px 2px 8px",
      }}
    >
      {title && (
        <div
          className={classNames(
            "title-section flex flex-row justify-between items-center mb-4 px-1",
            {
              "border-b border-gray-200 pb-2": !noHeaderBorder,
            }
          )}
        >
          <div className="font-semibold text-lg text-gray-800">
            <div className="bg-gray-200 rounded-lg p-2 flex justify-center items-center">
              {icon}
            </div>
          </div>
          <div className="flex flex-row justify-between items-center px-2 flex-1">
            <div className="">
              <div className="font-semibold text-gray-800">{title}</div>
              {subHeading && (
                <div className="text-gray-600 text-sm">{subHeading}</div>
              )}
            </div>
            {extraContent}
          </div>
        </div>
      )}
      {children}
    </div>
  );
};
