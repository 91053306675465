import { SDK } from "@sdk";
import { iConversation } from "@sdk/services/models";
import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { conversationsDefaultQueryConfig } from "./conversations-default-query";
import { ConversationsSlice } from "./conversations.slice";

export const loadAllConversations = (
  store: Store<iStore>,
  forceReload?: boolean
) =>
  loadConversationsQuery(conversationsDefaultQueryConfig, "all")(
    store,
    forceReload
  );

export const {
  loadEntityQueries: loadConversationsQuery,
  loadEntityById: loadConversationById,
  loadEntitiesByIds: bulkLoadConversationsByIds,
} = CreateHelpersForEntity<iStore, iConversation>({
  sliceName: "conversations",
  slice: ConversationsSlice,
  isPaginatedQueries: false,
  loadEntityQueryProvider: (query) => SDK.getAllConversations(),
  loadEntityByIdProvider: (entityId: string) => {
    throw "Not Implemented";
  },
  loadEntityByIdsProvider: (conversationIds: string[]) => {
    throw "Not Implemented";
  },
});
