import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { hot } from "react-hot-loader/root";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import i18n from "i18next";
import { ReactI18NextConfig } from "react-i18config";
import { initReactI18next } from "react-i18next";
import i18Backend from "i18next-http-backend";

declare const CC_BUILD_TIME: any;

window.addEventListener("error", (event) => {
  const chunkLoadingErrors = [
    "ChunkLoadError",
    "Chunk",
    "chunk",
    "Loading chunk",
  ];

  if (chunkLoadingErrors.some((v) => event.message.includes(v))) {
    console.warn("Chunk load error detected. Reloading...");
    window.location.reload();
    return;
  }
});

i18n.use(initReactI18next).use(i18Backend).init(ReactI18NextConfig);

const WithHotReload = process.env.NODE_ENV === "production" ? App : hot(App);

ReactDOM.render(
  // eslint-disable-next-line i18next/no-literal-string
  <Suspense fallback={<></>}>
    <WithHotReload />
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

console.log("CC: Build", CC_BUILD_TIME);
