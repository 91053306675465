import { SDK } from "@sdk";

import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { ticketsDefaultQueryConfig } from "./tickets-default-query";
import { TicketsSlice } from "./tickets.slice";
import { iTicket } from "@sdk/services/tickets-models";

export const loadAllTickets = (store: Store<iStore>, forceReload?: boolean) =>
  loadTicketsQuery(ticketsDefaultQueryConfig, "all")(store, forceReload);

export const {
  loadEntityQueries: loadTicketsQuery,
  loadEntityById: loadTicketById,
  loadEntitiesByIds: bulkLoadTicketsByIds,
} = CreateHelpersForEntity<iStore, iTicket>({
  sliceName: "tickets",
  slice: TicketsSlice,
  isPaginatedQueries: false,
  loadEntityQueryProvider: (query) => SDK.getAllTickets(),
  loadEntityByIdProvider: (entityId: string) => {
    throw "Not Implemented";
  },
  loadEntityByIdsProvider: (ticketIds: string[]) => {
    throw "Not Implemented";
  },
});
