import { EntityServices } from "@sdk/utils/entity.service";
import { iArticle, iArticleCollection } from "./knowledge-base-models";

import { axios, AxiosResponse } from "../axios";
import { PaginatedOptions } from "@sdk/utils/paginated-results";

export class KnowledgeBaseService {
  getCurrentConfig = () => {
    return this.config;
  };

  constructor(protected config: { basePath: string; token: string }) {}

  /* -------------------------------- Connection -------------------------------- */

  articles = Object.assign(
    new EntityServices<iArticle>(this.config, "articles"),
    {
      searchArticles: async (
        query: string,
        organizationId: string,
        portalId: string
      ) => {
        const results: AxiosResponse<iArticle[]> = await axios.post(
          this.config.basePath +
            `/articles/public/search?organizationId=${organizationId}&portalId=${portalId}`,
          {
            query,
            useEmbeddings: false,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          }
        );
        return results.data;
      },
      rateArticle: async (
        articleId: string,
        req: { rating: number; feedback?: string }
      ) => {
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/articles/public/${articleId}/rate-article`,
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          }
        );
        return results.data;
      },
      logArticleView: async (articleId: string) => {
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/articles/public/${articleId}/view-log`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          }
        );
        return results.data;
      },
    }
  );

  collections = Object.assign(
    new EntityServices<iArticleCollection>(this.config, "article-collections"),
    {
      queryPublic: async (req: { query: any; options: PaginatedOptions }) => {
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/article-collections/public/query`,
          {},
          {
            params: {
              organizationId: "//Todo",
            },
          }
        );
        return results.data;
      },
    }
  );
}
