import { axios, AxiosResponse } from "./axios";
import { DummyCreateSessionResponse } from "../data/dummy-data-session";
import { PaginatedResults } from "../utils/paginated-results";
import { iChatBot, iMessageNode } from "./chat-bot-models";
import {
  iConversation,
  iCreateSessionResponse,
  iLogPageVisitRequest,
  iMessage,
  iMessageAttachment,
  iSendRequestBody,
  iSessionInitialData,
  iSessionPublicData,
  iUser,
  iWidget,
  UpdateContactDetails,
} from "./models";
import {
  iMatchedKnowledgeDocument,
  TypeOfQuery,
} from "./knowledge-documents-model";
import { iTicket } from "./tickets-models";
import { iOrganizationHelpDeskTicketType } from "./ticket-types-model";
import { iScreenTourLog } from "./screent-tour-models";
import { iActivatedMessageCampaign } from "./activated-message-campaigns-models";
import { iCheckListInstance } from "./checklists/checklists-models";
import { iGPTResponse } from "./ai-assistant-models";

export class WidgetServices {
  constructor(protected config: { basePath: string; token: string }) {}

  getCurrentConfig = () => {
    return this.config;
  };

  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config); // Note: I don't understand this
  }

  /*************************
   * WIDGET CONFIGURATIONS *
   *************************/

  async getWidgetConfigurations(widgetId: string): Promise<Partial<iWidget>> {
    const results: AxiosResponse<Partial<iWidget>> = await axios.get(
      this.config.basePath + `/chat-widget/sessions/widgets/${widgetId}`,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async getChatBotFirstNodeConfiguration(
    widgetId: string,
    chatBotId: string
  ): Promise<Partial<iMessageNode>> {
    const results: AxiosResponse<Partial<iMessageNode>> = await axios.get(
      this.config.basePath +
        `/chat-widget/sessions/widgets/${widgetId}/chat-bots/${chatBotId}/min`,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async getUserList(widgetId: string): Promise<iUser[]> {
    const results: AxiosResponse<iUser[]> = await axios.get(
      this.config.basePath + `/chat-widget/sessions/users`,
      {
        headers: {
          Authorization: this.config.token,
        },
        params: {
          widgetId,
          min: "true",
        },
      }
    );
    return results.data;
  }

  async getUserById(userId: string, widgetId: string): Promise<iUser> {
    const results: AxiosResponse<iUser> = await axios.get(
      this.config.basePath + `/chat-widget/sessions/users/${userId}`,
      {
        headers: {
          Authorization: this.config.token,
        },
        params: {
          widgetId,
        },
      }
    );
    return results.data;
  }

  async sendRequest(req: iSendRequestBody) {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + `/cc/send-to-team`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async sendError(req: iSendRequestBody) {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + `/cc/send-error-log`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async getOrderStatus(widgetId: string, orderId: string) {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath +
        `/chat-widget/sessions/widgets/${widgetId}/query-shopify-order-status`,
      { orderId },
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async resolveShopifyCustomer(widgetId: string, customerId: string) {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath +
        `/chat-widget/sessions/widgets/${widgetId}/resolve-shopify-customer`,
      { customerId },
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async resolveShopifyCustomerOrders(widgetId: string, customerId: string) {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath +
        `/chat-widget/sessions/widgets/${widgetId}/resolve-shopify-customer-orders`,
      { customerId },
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  /*************************
   * Session and Contacts *
   *************************/

  async createSession(): Promise<Partial<iCreateSessionResponse>> {
    return DummyCreateSessionResponse;
  }

  async getFullSessionData() {
    const results: AxiosResponse<iSessionInitialData> = await axios.get(
      this.config.basePath + `/chat-widget/sessions/all`,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async getSessionData() {
    const results: AxiosResponse<iSessionPublicData> = await axios.get(
      this.config.basePath + `/chat-widget/sessions/me`,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async updateContactDetails(data: Partial<UpdateContactDetails>) {
    const results: AxiosResponse<iSessionPublicData> = await axios.patch(
      this.config.basePath + `/chat-widget/sessions/me`,
      data,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async updateReferer(data: { url: string; title?: string }) {
    const results: AxiosResponse<iSessionPublicData> = await axios.patch(
      this.config.basePath + `/chat-widget/sessions/me/referer`,
      data,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async updateDevice(data: {
    screenW: number;
    screenH: number;
    timezone: string;
    timezoneOffset: number;
  }) {
    const results: AxiosResponse<iSessionPublicData> = await axios.patch(
      this.config.basePath + `/chat-widget/sessions/me/device`,
      data,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async updateLandedPage(data: { url: string; title?: string }) {
    const results: AxiosResponse<iSessionPublicData> = await axios.patch(
      this.config.basePath + `/chat-widget/sessions/me/landed-page`,
      data,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async identifyUser(data: {
    id?: string;
    firstName?: string;
    lastName?: string;
    primaryEmail?: string;
    primaryMobile?: string;
    company?: {
      id?: string;
      name: string;
    };
  }) {
    const results: AxiosResponse<
      {
        success?: true;
        changed?: false;
      } & Partial<iSessionInitialData>
    > = await axios.post(
      this.config.basePath + `/chat-widget/sessions/me/identify-with-status`,
      data,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  /*************************
   * Conversations and Messages *
   *************************/

  async getAllConversations(): Promise<iConversation[]> {
    const results: AxiosResponse<iConversation[]> = await axios.get(
      this.config.basePath + `/chat-widget/sessions/conversations`,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async createNewConversation({
    campaignId,
    campaignMessage,
    startedPage,
    contactData,
    additionalData,
    chatBotId,
    subjectLine,
  }: {
    campaignId?: string;
    campaignMessage?: {
      text: string;
      timestamp: number;
      inviteeId: string;
      additionalMessages?: { message: string }[];
    };
    startedPage?: string;
    contactData?: {
      firstName?: string;
      lastName?: string;
      primaryEmail?: string;
    };
    additionalData?: any;
    chatBotId?: string;
    subjectLine?: string;
  }) {
    const results: AxiosResponse<{
      routingStatus: boolean;
      conversation: iConversation;
    }> = await axios.post(
      this.config.basePath + `/chat-widget/sessions/conversations`,
      {
        campaignId,
        campaignMessage,
        startedPage,
        contactData,
        additionalData,
        chatBotId,
        subjectLine,
      },
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async createNewTicket({
    ticketData,
    contactData,
  }: {
    ticketData: any;
    contactData?: {
      firstName?: string;
      lastName?: string;
      primaryEmail?: string;
    };
  }) {
    const results: AxiosResponse<{
      routingStatus: boolean;
      conversation: iConversation;
    }> = await axios.post(
      this.config.basePath +
        `/chat-widget/sessions/conversations/create-ticket`,
      {
        ticketData,
        contactData,
      },
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async endConversation(conversationId: string) {
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath +
        `/chat-widget/sessions/conversations/${conversationId}/archive`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async skipFormSubmission(conversationId: string) {
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath +
        `/chat-widget/sessions/conversations/${conversationId}/skip-form-submission`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async rateConversation(
    conversationId: string,
    req: { rating: number; comment: string }
  ) {
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath +
        `/chat-widget/sessions/conversations/${conversationId}/rating`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async rateMagicAssistantResponse(req: {
    responseId: string;
    rating?: "HAPPY" | "NOT_HAPPY";
  }) {
    const results: AxiosResponse<{ success: boolean }> = await axios.post(
      this.config.basePath +
        `/chat-widget/sessions/conversations/rate-magic-assistant-message`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async deActivateBot(conversationId: string) {
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath +
        `/chat-widget/sessions/conversations/${conversationId}/deactivate-bot`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async sendConversationRatings() {
    return {
      status: true,
    };
  }

  async getMessagesByConversationId(
    conversationId: string,
    limit = 100,
    offset = 0
  ) {
    const results: AxiosResponse<PaginatedResults<iMessage>> = await axios.get(
      this.config.basePath +
        `/chat-widget/sessions/conversations/${conversationId}/messages`,
      {
        headers: {
          Authorization: this.config.token,
        },
        params: { limit, offset },
      }
    );
    return results.data;
  }

  async sendMessage(
    conversationId: string,
    message: {
      timestamp?: number;
      text: string;
      private?: boolean;
      attachments?: iMessageAttachment[];
      references?: string[];
      tempId?: number;
      otr?: boolean;
      data?: any;
    }
  ) {
    const results: AxiosResponse<{
      routingStatus: boolean;
      conversation: iConversation;
    }> = await axios.post(
      this.config.basePath +
        `/chat-widget/sessions/conversations/${conversationId}/messages`,
      message,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async logMagicAssistantEvent(
    conversationId: string,
    message: {
      timestamp?: number;
      text: string;
      by: "CLIENT" | "ASSISTANT";
    }
  ) {
    const results: AxiosResponse<{
      routingStatus: boolean;
      conversation: iConversation;
    }> = await axios.post(
      this.config.basePath +
        `/chat-widget/sessions/conversations/${conversationId}/magic-assistant-message`,
      message,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async sendReadSignals(
    conversationId: string,
    conversationIsTemporary: Boolean,
    timestamp: number
  ) {
    if (!conversationIsTemporary) {
      const results: AxiosResponse<{
        status: boolean;
      }> = await axios.post(
        this.config.basePath +
          `/chat-widget/sessions/conversations/${conversationId}/read`,
        { timestamp },
        {
          headers: {
            Authorization: this.config.token,
          },
        }
      );
      return results.data;
    }
  }

  async onCalendarEventScheduled(
    conversationId: string,
    messageId: string,
    data: any
  ) {
    const results: AxiosResponse<{
      status: boolean;
    }> = await axios.post(
      this.config.basePath +
        `/chat-widget/sessions/conversations/${conversationId}/on-calendar-event-scheduled`,
      { messageId, data },
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  /*************************
   * Campaigns and Tours *
   *************************/

  async getActiveTours() {
    const results: AxiosResponse<iScreenTourLog[]> = await axios.post(
      this.config.basePath + `/chat-widget/sessions/tours/get-active-tours`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async getActiveMessageCampaigns() {
    const results: AxiosResponse<
      iActivatedMessageCampaign[]
    > = await axios.post(
      this.config.basePath +
        `/chat-widget/sessions/campaigns/get-active-campaigns`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async markMessageCampaignAsSeen(messageCampaignId: string) {
    const results: AxiosResponse<
      iActivatedMessageCampaign[]
    > = await axios.post(
      this.config.basePath +
        `/chat-widget/sessions/campaigns/mark-message-campaign-as-seen`,
      {
        messageCampaignId,
      },
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  /*************************
   * Activities and Events *
   *************************/

  async sendPageHitActivity(req: iLogPageVisitRequest) {
    const results: AxiosResponse<{
      status: boolean;
    }> = await axios.post(
      this.config.basePath + `/chat-widget/sessions/activities/log-page-hit`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async addActivity(req: { type: string; data: any }) {
    const results: AxiosResponse<{
      status: boolean;
    }> = await axios.post(
      this.config.basePath + `/chat-widget/sessions/activities/log-activity`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async queryKnowledge(req: {
    query: string;
    assistantId?: string;
    chatHistory?: string;
    conversationId?: string;
    noSkipNoMatchedContext?: boolean;
    contextKeywords?: string;
  }) {
    const results: AxiosResponse<{
      response: string;
      matchedDocuments: iMatchedKnowledgeDocument[];
      typeOfQuery: TypeOfQuery;
      queryId: string;
    }> = await axios.post(
      this.config.basePath + `/knowledge-documents/query-knowledge`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async processMagicAssistant(req: {
    message: string;
    chatHistory: {
      role: string;
      content: string;
    }[];
    assistantId?: string;
  }) {
    const results: AxiosResponse<iGPTResponse> = await axios.post(
      this.config.basePath + `/ai-assistant/process-customer-message`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  /*************************
   * Tickets Apis *
   *************************/

  async createTicket(req: Partial<iTicket>) {
    const results: AxiosResponse<iTicket> = await axios.post(
      this.config.basePath + `/chat-widget/sessions/tickets`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async getAllTickets() {
    const results: AxiosResponse<iTicket[]> = await axios.get(
      this.config.basePath + `/chat-widget/sessions/tickets`,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async getAllTicketTypes() {
    const results: AxiosResponse<
      iOrganizationHelpDeskTicketType[]
    > = await axios.get(
      this.config.basePath +
        `/chat-widget/sessions/tickets/config/ticket-types`,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async getTicketTypeById(ticketTypeId: string) {
    const results: AxiosResponse<iOrganizationHelpDeskTicketType> = await axios.get(
      this.config.basePath +
        `/chat-widget/sessions/tickets/config/ticket-types/${ticketTypeId}`,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async sendOTP(email: string) {
    const results: AxiosResponse<{ success: true }> = await axios.post(
      this.config.basePath + `/chat-widget/sessions/auth/get-otp`,
      {
        email,
      },
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  async verifyWithOTP(code: string) {
    const results: AxiosResponse<{ success: true }> = await axios.post(
      this.config.basePath + `/chat-widget/sessions/auth/verify-with-otp`,
      {
        code,
      },
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }

  /*************************
   * CC Apis *
   *************************/

  async sendErrorLog(req: iSendRequestBody) {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + `/cc/send-error-log`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      }
    );
    return results.data;
  }
}
