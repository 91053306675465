import React from "react";
import "./loading-indicator.scss";

export const LoadingIndicator = ({
  size = "1.7rem",
  style = {},
}: {
  size?: number | string;
  style?: React.CSSProperties;
}) => {
  return (
    <div
      className="loader animate-spin"
      style={{ width: size, height: size, ...style }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        className="icon-svg"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 3a9 9 0 0 1 9 9h-2a7 7 0 0 0-7-7V3z" />
      </svg>
    </div>
  );
};
