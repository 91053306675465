import { SDK } from "@sdk";
import { iUser } from "@sdk/services/models";
import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { usersDefaultQueryConfig } from "./users-default-query";
import { UsersSlice } from "./users.slice";
import {
  selectAllActiveUsers,
  selectRandomUser,
  selectUserById,
} from "./users.selectors";
import { AnyAction } from "@reduxjs/toolkit";
import { selectWidget } from "../session/session.selectors";

export const loadAllUsers = (widgetId: string) => (
  store: Store<iStore>,
  forceReload?: boolean
) =>
  loadUsersQuery({ ...usersDefaultQueryConfig, query: { widgetId } }, "all")(
    store,
    forceReload
  );

export const getUserById = async (userId: string, store: Store<iStore>) => {
  let user = selectUserById(userId)(store.getState());
  if (!user) {
    // Load User
    const widget = selectWidget(store.getState());
    const res = await loadUserBydId(userId, widget?.id!)(store);
  }
  user = selectUserById(userId)(store.getState());
  return user;
};

export const getAllActiveUsers = async (store: Store<iStore>) => {
  const widget = selectWidget(store.getState());
  await loadAllUsers(widget?.id!);
  return selectAllActiveUsers(store.getState());
};

export const getRandomUser = async (store: Store<iStore>) => {
  let user = selectRandomUser(store.getState());
  if (!user) {
    // Load All Users
    const widget = selectWidget(store.getState());
    await loadAllUsers(widget?.id!);
  }
  user = selectRandomUser(store.getState());
  return user;
};

export const loadUserBydId = (entityId: string, widgetId: string) => {
  if (!entityId || entityId === "cc" || entityId === "CC" || entityId === "SYSTEM") {
    return async () => {};
  }
  return _loadUserById(entityId, widgetId)() as any;
};

export const {
  loadEntityQueries: loadUsersQuery,
  loadEntityById: _loadUserById,
  loadEntitiesByIds: bulkLoadUsersByIds,
} = CreateHelpersForEntity<iStore, iUser>({
  sliceName: "users",
  slice: UsersSlice,
  isPaginatedQueries: false,
  loadEntityQueryProvider: (query) =>
    SDK.getUserList((query.query as any).widgetId),
  loadEntityByIdProvider: ((entityId: string, widgetId: string) => {
    return SDK.getUserById(entityId, widgetId);
  }) as any,
  loadEntityByIdsProvider: (userIds: string[]) => {
    throw "Not Implemented";
  },
});
