export function convertMillisecondsToReadableString(
  time: number = 0,
  showMilliseconds?: boolean,
  hideSeconds?: boolean
) {
  const milliseconds = time % 1000;
  time = parseInt((time / 1000).toString(), 10);
  const seconds = time % 60;
  time = parseInt((time / 60).toString(), 10);
  const minutes = time % 60;
  time = parseInt((time / 60).toString(), 10);
  const hours = time % 24;
  let out = "";
  if (hours && hours > 0) {
    out += hours + " " + (hours === 1 ? "hour" : "hours") + " ";
  }
  if (minutes && minutes > 0) {
    out += minutes + " " + (minutes === 1 ? "minute" : "minutes") + " ";
  }
  if (!hideSeconds && seconds && seconds > 0) {
    out += seconds + " " + (seconds === 1 ? "second" : "seconds") + " ";
  }
  if (showMilliseconds && milliseconds && milliseconds > 0) {
    out +=
      milliseconds +
      " " +
      (milliseconds === 1 ? "millisecond" : "milliseconds") +
      " ";
  }
  return out.trim();
}
