import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import takeRight from "lodash/takeRight";
import { iAppState, iPreInitConfig, PageVisitTrace } from "./app-state.model";
import { boolean } from "@storybook/addon-knobs";

export const appSlice_initialState: iAppState = {
  audio: {
    isMuted: false,
  },
  isMaximized: false,
  isSocketConnected: false,
  appInitTime: Date.now(),
  lastVisitedTab: "HOME",
  campaignState: {
    eventTraces: {
      pagesVisits: [],
    },
  },
  preferredSubjectLine: undefined,
};

const appStateSlice = createSlice({
  name: "appState",
  initialState: appSlice_initialState,
  reducers: {
    setInit(state, action: PayloadAction<any>) {
      state.appInitTime = Date.now();
    },
    setMuteState(state, action: PayloadAction<boolean>) {
      state.audio.isMuted = action.payload;
    },
    setWidgetMaximizedState(state, action: PayloadAction<boolean>) {
      state.isMaximized = action.payload;
      if (action.payload) {
        state.isMaximizedAtLeastOnce = true;
      }
    },
    setLastVisitedTab(state, action: PayloadAction<string>) {
      state.lastVisitedTab = action.payload;
    },
    setSocketConnectionState(state, action: PayloadAction<boolean>) {
      state.isSocketConnected = action.payload;
    },
    addCampaignEvent(state, action: PayloadAction<PageVisitTrace>) {
      if (!state.campaignState.eventTraces) {
        state.campaignState.eventTraces = {
          pagesVisits: [],
        };
      }
      state.campaignState.eventTraces.pagesVisits.push(action.payload);
      state.campaignState.eventTraces.pagesVisits = takeRight(
        state.campaignState.eventTraces.pagesVisits,
        10
      );
    },
    setActiveCampaign(
      state,
      action: PayloadAction<{ campaignId: string; campaignUser?: string }>
    ) {
      state.campaignState.activatedCampaign = action.payload.campaignId;
      state.campaignState.campaignUser = action.payload.campaignUser;
      state.campaignState.activatedTimestamp = Date.now();
    },
    clearActiveCampaign(state, action: PayloadAction<{}>) {
      state.campaignState.activatedCampaign = "";
      state.campaignState.campaignUser = "";
      state.campaignState.activatedTimestamp = 0;
    },
    setPreferredSubjectLine(state, action: PayloadAction<string>) {
      state.preferredSubjectLine = action.payload;
    },
    setPreInitConfig(state, action: PayloadAction<iPreInitConfig>) {
      state.preInitConfig = action.payload;
    },
    setIsClientIPBlocked(state, action: PayloadAction<boolean>) {
      state.isClientIPBlocked = action.payload;
    },
  },
});

export const AppStateSliceReducers = appStateSlice.reducer;

export const AppStateSliceActions = appStateSlice.actions;
