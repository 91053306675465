import {
  PaginatedOptions,
  PaginatedResults,
} from "@sdk/utils/paginated-results";
import { axios, AxiosResponse } from "../axios";

import { iNewsFeedItem, iNewsFeedType } from "./newsfeed-models";

export class PublicNewsFeedService {
  constructor(
    protected config: {
      basePath: string;
      token: string;
      organizationId: string;
    }
  ) {}

  /*************************
   * NewsFeed Apis *
   *************************/

  async getAllNewsFeedItems(organizationId: string, ticketTypeId: string) {
    const results: AxiosResponse<iNewsFeedItem[]> = await axios.get(
      this.config.basePath + `/newsfeed/public`,
      {
        headers: {},
        params: {
          organizationId,
          ticketTypeId,
        },
      }
    );
    return results.data;
  }

  async queryNewsFeedItems(req: { query: any; options: PaginatedOptions }) {
    const results: AxiosResponse<
      PaginatedResults<iNewsFeedItem>
    > = await axios.post(this.config.basePath + `/newsfeed/public/query`, req, {
      headers: {},
      params: {
        organizationId: this.config.organizationId || req.query.organizationId,
      },
    });
    return results.data;
  }

  async getNewsfeedItem(id: string) {
    const results: AxiosResponse<iNewsFeedItem> = await axios.get(
      this.config.basePath + `/newsfeed/public/${id}`,
      {
        headers: {},
        params: {},
      }
    );
    return results.data;
  }

  async reactToNewsfeedItem(newsfeedItemId: string, req: { reaction: string }) {
    const results: AxiosResponse<iNewsFeedItem> = await axios.post(
      this.config.basePath + `/newsfeed/public/${newsfeedItemId}/react`,
      req,
      {
        headers: {
          authorization: this.config.token,
        },
        params: {},
      }
    );
    return results.data;
  }

  async getAllNewsfeedConfig(organizationId: string) {
    const results: AxiosResponse<iNewsFeedType[]> = await axios.get(
      this.config.basePath + `/newsfeed/public/config/newsfeed-types`,
      {
        headers: {},
        params: {
          organizationId,
        },
      }
    );
    return results.data;
  }

  async getNewsfeedTypeById(organizationId: string, newsfeedId: string) {
    const results: AxiosResponse<iNewsFeedType> = await axios.get(
      this.config.basePath +
        `/newsfeed/public/config/newsfeed-types/${newsfeedId}`,
      {
        headers: {
          authorization: this.config.token,
        },
        params: {
          organizationId,
        },
      }
    );
    return results.data;
  }
}
