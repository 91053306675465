import { ParentWindowService } from "@parent-window/parent-window";
import { useSimpleState } from "@sdk/utils/use-simple-state";
import { iCompactViewState } from "app-router";
import { CloseIcon } from "components/common/icons/close-icon";
import React, { Suspense, useCallback, useEffect, useRef } from "react";
import { useSelector, useStore } from "react-redux";
import {
  selectActivatedCheckLists,
  selectWidget,
} from "store/modules/session/session.selectors";
import { GlobalEventEmitter } from "utils/global-event-emitter";
import { useLocalStorageStore } from "utils/use-local-storage-store";
import { CheckList } from "views/checklist-page/checklist.page";
import { NewsfeedItemWithLoader } from "views/newsfeed-item-page/newsfeed-item-card";

export const AppCompact = ({
  compactViewState,
  setCompactViewState,
}: {
  compactViewState: iCompactViewState;
  setCompactViewState: (edits: iCompactViewState) => void;
}) => {
  const widget = useSelector(selectWidget);
  const [
    _lastShownNewsfeedTimestamp,
    setLastShownNewsfeedTimestamp,
  ] = useLocalStorageStore(`CC_LAST_SEEN_NEWS_${widget?.id!}`, "");

  useEffect(() => {
    // Create a style element
    const style = document.createElement("style");
    style.innerHTML = "html { font-size: 12px !important; }";
    // Append the style element to the document head
    document.head.appendChild(style);
    // Cleanup function to remove the style element on unmount
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <div className="overflow-auto h-full w-full">
      {compactViewState.view !== "NONE" && (
        <div
          className="absolute right-0 top-0 pr-8 pt-8 cursor-pointer z-10"
          onClick={() => {
            if (compactViewState.view === "NEWSFEED") {
              setLastShownNewsfeedTimestamp(Date.now().toString());
              setTimeout(() => {
                setCompactViewState({ view: "NONE", checkListId: undefined });
              }, 0);
            } else {
              setCompactViewState({ view: "NONE", checkListId: undefined });
            }
          }}
        >
          <CloseIcon size={"1.5rem"} />
        </div>
      )}
      {compactViewState.view !== "NONE" && (
        <>
          <CompactViewOuter compactViewState={compactViewState}>
            {compactViewState.view === "CHECK_LIST" &&
              compactViewState.checkListId && (
                <div className="p-4">
                  <CheckList
                    checklistInstanceId={compactViewState.checkListId}
                  />
                </div>
              )}
            {compactViewState.view === "NEWSFEED" &&
              compactViewState.newsfeedItemId && (
                <div className="p-4">
                  <NewsfeedItemWithLoader
                    newsfeedItemId={compactViewState.newsfeedItemId!}
                    mode="PAGE"
                    onInteracted={() => {
                      setLastShownNewsfeedTimestamp(Date.now().toString());
                    }}
                    truncatedView={true}
                  />
                </div>
              )}
          </CompactViewOuter>
        </>
      )}
    </div>
  );
};

const CompactViewOuter = ({ children, compactViewState }) => {
  useEffect(() => {
    ParentWindowService.sendMessage({
      type: "SET_COMPACT_MODE",
      data: {
        isCompactMode: true,
      },
    });
    return () => {
      // Turn compact mode off
      ParentWindowService.sendMessage({
        type: "SET_COMPACT_MODE",
        data: {
          isCompactMode: false,
        },
      });
    };
  }, []);

  const compactAppDivRef = useRef<HTMLDivElement>(null);

  const updateHeight = useCallback(() => {
    if (compactAppDivRef.current) {
      // console.log("compactAppDivRef", compactAppDivRef);
      const initialHeight = compactAppDivRef.current.offsetHeight;

      const initialWidth = compactAppDivRef.current.offsetWidth;
      // console.log("Initial Dimension:", initialWidth, initialHeight);
      ParentWindowService.sendMessage({
        type: "SET_COMPACT_MODE_DIMENSIONS",
        data: {
          dimension: {
            width: initialWidth,
            height: initialHeight,
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      for (let entry of entries) {
        if (entry.target === compactAppDivRef.current) {
          const newHeight = entry.contentRect.height;
          const newWidth = entry.contentRect.width;
          //   console.log("Size changed:", newWidth, newHeight);
          ParentWindowService.sendMessage({
            type: "SET_COMPACT_MODE_DIMENSIONS",
            data: {
              dimension: {
                width: newWidth,
                height: newHeight,
              },
            },
          });
        }
      }
    };
    const observer = new ResizeObserver(handleResize);

    if (compactAppDivRef.current) {
      observer.observe(compactAppDivRef.current);
      updateHeight(); // Call this to handle first render
    }

    return () => {
      if (compactAppDivRef.current) {
        observer.unobserve(compactAppDivRef.current);
      }
    };
  }, [updateHeight]);

  useEffect(() => {
    updateHeight();
  }, [updateHeight, compactViewState]);

  const store = useStore();

  useEffect(()=>{
    document.body.classList.add('compact-app');
    return ()=>{
      document.body.classList.remove('compact-app')
    }
  },[])

  return (
    <div ref={compactAppDivRef} className="animate fade-in-right">
      {children}
    </div>
  );
};
