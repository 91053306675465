import { LightenDarkenColor } from "utils/color-utils";

export const GenerateColorTheme = (themeColor: string, textColor: string) => {
  return /*css*/ `
      .primary-button {
        background: ${themeColor};
        color:#fff;
      }

      .text-primary{
        color:${themeColor};
      }

      .hover-text-primary:hover{
        color: ${themeColor};
      }

      .border-primary{
        border-color: ${themeColor} !important;
      }

      .hover-border-primary-500:hover{
        border-color: ${themeColor} !important;
      }

      .primary-button:hover{
        background: ${LightenDarkenColor(themeColor, 20)};
      }

      .secondary-button {
        background: ${LightenDarkenColor(themeColor, 210, 244)};
        color: ${themeColor};
      }

      .secondary-button:hover{
        background: ${LightenDarkenColor(themeColor, 200, 230)};
      }

      .bg-primary-100{
       background: ${LightenDarkenColor(themeColor, 170, 190)};
      }

      .hover-bg-primary-100:hover{
       background: ${LightenDarkenColor(themeColor, 170, 190)};
      }

      .quick-reply-pill {
        border: 2px solid ${themeColor};
        color: ${themeColor}
      }

      .quick-reply-pill:hover {
        background: ${LightenDarkenColor(themeColor, 200, 230)};
      }

      .primary-bg {
        background: ${themeColor};
      }

      .primary-bg-200 {
        background: ${LightenDarkenColor(themeColor, 200, 230, 230)};
      }

      .article-collection-heading {
        background: ${LightenDarkenColor(themeColor, 200, 230)};
        color: ${themeColor}
      }
    

      .chat-messages .message-row.me > .bubble{
        color:#fff;
        background-color: ${themeColor} !important;
      }


      .primary-gradient-bg {
        background: ${themeColor};
        background: radial-gradient(${themeColor}, ${LightenDarkenColor(
    themeColor,
    -20
  )});
      }

      .primary-gradient-bg-3 {
         position: relative;
        overflow: hidden;
      }
.primary-gradient-bg-3::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 220%;
  height: 220%;
  background: linear-gradient(117.67deg, 
      ${LightenDarkenColor(themeColor, -120)}, 
      ${themeColor}, 
      ${LightenDarkenColor(themeColor, 140)}
  );
  animation: gradient-rotation 5s linear infinite;
  z-index: -1; /* Ensure the gradient stays behind other content */
}

@keyframes gradient-rotation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

      .body-bg {
        // background: ${LightenDarkenColor(themeColor, -100)};
        background: #f4f4ff4;
      }

      .body-bg #root {
           border-right: 1px solid #cccccc;
    border-left: 1px solid #cccccc;
      }

      .primary-gradient-bg-2 {
        background: ${themeColor};
        background: radial-gradient(${themeColor}, ${LightenDarkenColor(
    themeColor,
    -40
  )});
      }

      .switch {
        --thumb-color-off:${LightenDarkenColor(themeColor, 150)};
        --thumb-color-on:${themeColor};
        --color-bg-on: ${LightenDarkenColor(themeColor, 215, 215)};
      }
      .switch__gfx{
        width:35px;
      }
      
  `;
};
