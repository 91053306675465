export function loadStyle(style, id) {
  let styleEle = document.getElementById(id);
  if (styleEle) {
    styleEle.innerHTML = style;
  } else {
    let styleEl = document.createElement("style");
    styleEl.id = id;
    styleEl.innerHTML = style;
    document.head.appendChild(styleEl);
  }
}
