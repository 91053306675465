export function LightenDarkenColor(
  color: string,
  amount: number,
  maxValue?: number,
  minValue?: number
) {
  return (
    "#" +
    color.replace(/^#/, "").replace(/../g, (color) => {
      return (
        "0" +
        Math.min(
          minValue || 255,
          Math.max(maxValue || 0, Math.max(0, parseInt(color, 16) + amount))
        ).toString(16)
      ).substr(-2);
    })
  );
}

// Chat GPT code - Seems to be working better for '#000000' - Got tot test this
// export function LightenDarkenColor(colorCode: string, amount: number) {
//   var usePound = false;

//   if (colorCode[0] === "#") {
//     colorCode = colorCode.slice(1);
//     usePound = true;
//   }

//   var num = parseInt(colorCode, 16);
//   var r = (num >> 16);
//   var b = ((num >> 8) & 0x00FF);
//   var g = (num & 0x0000FF);

//   // Check if the color is dark
//   if (r < 10 && g < 10 && b < 10 && amount < 0) {
//     amount = -amount; // Invert the amount for dark colors
//   }

//   r += amount;
//   g += amount;
//   b += amount;

//   // Clamp values between 0 and 255
//   r = Math.min(255, Math.max(0, r));
//   g = Math.min(255, Math.max(0, g));
//   b = Math.min(255, Math.max(0, b));

//   return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16).padStart(6, '0');
// }
