import { iConversation } from "@sdk/services/models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { conversationsDefaultQueryConfig } from "./conversations-default-query";

export const {
  slice: ConversationsSlice,
  reducers: ConversationsSliceReducer,
  actions: {
    setEntityQueryResults: setConversationsQueryResults,
    setQueryList: setConversationsQueryList,
    setEntity: setConversation,
    setEntities: setConversations,
    patchEntity: patchConversation,
    addNewEntity: addNewConversation,
    setEntityFetchingStatus: setConversationFetchingStatus,
    setEntitiesFetchingStatus: setConversationsFetchingStatus,
    setEntityFailedStatus: setConversationFailedStatus,
    setEntityQueryError: setConversationsQueryError,
    resetQuery: resetConversationsQuery,
  },
} = CreateEntitySlice<iConversation>({
  sliceName: "conversations",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...conversationsDefaultQueryConfig,
        alias: "all",
      };
    } else if (queryAlias === "min") {
      return {
        ...conversationsDefaultQueryConfig,
        alias: "min",
      };
    }
  },
});
