/* eslint-disable no-useless-escape */

export function getUrlData(url: string): UrlDataObj {
  const match = url.match(
    /^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/
  )!;
  if (!url) {
    url = window.location.href;
  }
  const question = url.indexOf("?");
  let hash = url.indexOf("#");
  if (hash === -1) {
    hash = url.length;
  }
  const query =
    question === -1 || hash === question + 1
      ? url.substring(hash)
      : url.substring(question + 1, hash);
  const result = {};
  query.split("&").forEach((part) => {
    if (!part) {
      return;
    }
    part = part.split("+").join(" "); // replace every + with space, regexp-free version
    const eq = part.indexOf("=");
    let key = eq > -1 ? part.substr(0, eq) : part;
    const val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : "";
    const from = key.indexOf("[");
    if (from === -1) {
      result[decodeURIComponent(key)] = val;
    } else {
      const to = key.indexOf("]", from);
      const index = decodeURIComponent(key.substring(from + 1, to));
      key = decodeURIComponent(key.substring(0, from));
      if (!result[key]) {
        result[key] = [];
      }
      if (!index) {
        result[key].push(val);
      } else {
        result[key][index] = val;
      }
    }
  });
  return {
    href: url,
    protocol: match?.[1],
    host: match[2],
    hostname: match[3],
    port: match[4],
    pathname: match[5],
    search: match[6],
    hash: match[7],
    queryParams: result,
  };
}

export interface UrlDataObj {
  href: string;
  protocol: string;
  host: string;
  hostname: string;
  port: string;
  pathname: string;
  search: string;
  hash: string;
  queryParams: {
    [key: string]: string;
  };
}
